<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" align="center" class="py-0">
        <v-row class="pa-10 px-md-16 justify-center">
          <div class="hafdarkgrey--text font-weight-bold text-h3 text-center">
            Now Install the Happyly App
            <v-col class="hafdarkgrey--text text-h5 text-lg-h6" cols="12">
              Please download & install Happyly via link you received in SMS or
              using the buttons below.
            </v-col>
          </div>
        </v-row>
        <v-img :src="happylyImage" max-width="300" class="mb-10"></v-img>
        <v-row align="center" justify="center" class="px-8">
          <v-col cols="12" md="6" class="pa-0">
            <v-col
              class="secondary--text font-weight-bold text-lg-h5"
              cols="12"
            >
              Find trusted family adventures and activities in under 60 seconds
            </v-col>
            <app-download no-title modal no-divider />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppDownload from '@/components/home/AppDownload'

export default {
  name: 'AppPage',
  components: {
    AppDownload,
  },
  data: () => ({
    happylyImage: require('@/assets/images/screenshots/happyly_redeem_code_phone.png'),
  }),
  mounted() {
    // Send event to Facebook Pixel
    this.$fb.track('PageView', { page: 'Download App' })
  },
}
</script>
